<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Trusted IP Addresses</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3">
        <b-button size="sm" variant="primary" @click="showModal">
          <b-icon-plus style="font-size: 1.2em" />
          Add
        </b-button>
        <b-icon-info-circle id="pop-info" class="clickable icon-info" />
        <b-popover
          target="pop-info"
          placement="right"
          triggers="hover focus"
          content="Users will not be prompted for 2FA code if they login from these IP Addresses."
        ></b-popover>
      </b-col>
    </b-row>
    <div ref="table-container">
      <div v-if="isBusy" class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
      <b-table
        bordered
        small
        striped
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        class="ip-table"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(trustedIPId)="data">
          <b-icon-pencil-square
            class="btn-inline-text"
            @click="showModalForEdit(data.item)"
            title="Edit"
          ></b-icon-pencil-square>
          <b-icon-trash
            class="btn-inline-text ml-1"
            @click="deleteItem(data.item)"
            title="Delete"
          ></b-icon-trash>
        </template>
      </b-table>
    </div>

    <b-modal
      centered
      ref="form-modal"
      :title="isEdit ? 'Edit IP Address' : 'Add IP Address'"
    >
      <b-form ref="main-form">
        <div class="form-group row">
          <div class="col-sm-3">
            <label>IP Address</label>
          </div>
          <div class="col-sm-9">
            <b-input
              class="form-control-sm"
              v-model.trim="formItem.ipAddress"
              required
              pattern="\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b"
              title="Invalid IP address"
              @change="$sanitize('formItem.ipAddress')"
            ></b-input>
          </div>
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="addOrEditItem"
          >{{ isEdit ? 'Edit' : 'Add' }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';

export default {
  /*global _*/
  mixins: [httpclient],
  mounted() {
    this.getList();
  },
  data() {
    return {
      path: 'trusted-ip',
      formItem: {
        trustedIPId: -1,
        ipAddress: '',
      },
      formItemDefault: {
        trustedIPId: -1,
        ipAddress: null,
      },
      isEdit: false,
      editId: -1,
      fields: [
        {
          key: 'ipAddress',
          label: 'IP Addresses',
          sortable: true,
        },
        {
          key: 'trustedIPId',
          label: '',
          sortable: false,
        },
      ],
      items: [],
      perPage: 10000,
      currentPage: 1,
      isBusy: false,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    showModal() {
      this.isEdit = false;
      this.editId = -1;
      this.formItem = Object.assign({}, this.formItemDefault);
      this.formItem.options = [''];
      this.$refs['form-modal'].show();
    },
    showModalForEdit(data) {
      this.isEdit = true;
      this.editId = data.trustedIPId;
      this.formItem = _.cloneDeep(_.assign({}, this.formItemDefault, data));
      this.$refs['form-modal'].show();
    },
    hideModal() {
      this.$refs['form-modal'].hide();
    },
    getList() {
      const vm = this;
      this.httpclient.get('api/trustedips').then(function (resp) {
        if (resp.data) {
          vm.items = resp.data;
          vm.$refs['form-modal'].hide();
        }
      });
    },
    deleteItem(data) {
      const vm = this;
      const confirmMessage = 'Remove the IP address?';
      const id = data.trustedIPId;

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.httpclient.delete('api/trustedips/' + id).then(function (resp) {
              if (resp.data) {
                let index = _.findIndex(vm.items, { trustedIPId: id });
                vm.items.splice(index, 1);
                vm.$refs['form-modal'].hide();
                vm.$bvModal.msgBoxOk(
                  'IP address had been removed successfully',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              }
            });
          } else {
            vm.$refs['form-modal'].show();
          }
        });
    },
    addOrEditItem() {
      const vm = this;
      const mainForm = vm.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      vm.$refs['form-modal'].hide();

      let confirmMessage = vm.isEdit
        ? 'Update the IP address?'
        : 'Add the IP address?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            if (vm.isEdit) {
              vm.editItem();
            } else {
              vm.addItem();
            }
          } else {
            vm.$refs['form-modal'].show();
          }
        });
    },
    editItem() {
      const vm = this;
      vm.httpclient
        .put('api/trustedips/' + vm.editId, vm.formItem)
        .then(function (resp) {
          if (resp.data) {
            let index = _.findIndex(vm.items, { trustedIPId: vm.editId });
            vm.items.splice(index, 1, vm.formItem);
            vm.$refs['form-modal'].hide();
            vm.$bvModal.msgBoxOk('IP address had been updated successfully', {
              okVariant: 'success',
              centered: true,
            });
          }
        });
    },
    addItem() {
      const vm = this;
      vm.httpclient.post('api/trustedips', this.formItem).then(function (resp) {
        if (resp.data) {
          vm.formItem.trustedIPId = resp.data;
          vm.items.push(vm.formItem);
          vm.$refs['form-modal'].hide();
          vm.$bvModal.msgBoxOk('IP address had been added successfully', {
            okVariant: 'success',
            centered: true,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ip-table {
  max-width: 300px;
}
</style>
